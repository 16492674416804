import React, { createContext } from 'react';
import { Appearance } from './appearance.types';

export const AppearanceContext = createContext<Appearance>({
  isSideNavCollapsed: false,
  setIsSideNavCollapsed: () => {},
});

export function AppearanceProvider({ children }: { children: any }) {
  const [isSideNavCollapsed, setIsSideNavCollapsed] = React.useState(false);

  return (
    <AppearanceContext.Provider value={{ isSideNavCollapsed, setIsSideNavCollapsed }}>
      {children}
    </AppearanceContext.Provider>
  );
}
