import React, { useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'auth/providers/AuthProvider';
import { AppearanceContext } from 'providers/appearance/AppearanceProvider';
import './ContentTemplatesPage.scss';

const appURL = process.env.REACT_APP_EXPERIENCE_BUILDER + '/#/content/templates/manage';
const host = process.env.REACT_APP_HOST_URL;

export default function ContentTemplatesPage() {
  const history = useHistory();
  const { token } = useContext(AuthContext);
  const frameRef = useRef(null);
  const { setIsSideNavCollapsed } = useContext(AppearanceContext);

  useEffect(() => {
    function loadHandler(evt: any){
      const frame = frameRef?.current ? (frameRef.current as HTMLIFrameElement) : null;
      if (
        evt.source !== frame?.contentWindow ||
        evt.origin !== process.env.REACT_APP_EXPERIENCE_BUILDER ||
        !evt?.data?.flb
      ) {
        return;
      }

      if (evt?.data?.type === 'flb.contenttemplatemanager.ready') {
        if (!frame || !token) {
          console.error('> Missing required frame inputs', frame, token?.slice(token?.length - 5));
          return;
        }
        frame.contentWindow?.postMessage(
          {
            flb: true,
            type: 'flb.session',
            token: token,
            host: host,
          },
          appURL,
        );
      } else if (evt?.data?.type === 'flb.contenttemplate.create') {
        setIsSideNavCollapsed(true);
        history.push('content-templates/create');
      } else if (evt?.data?.type === 'flb.contenttemplate.edit') {
        setIsSideNavCollapsed(true);
        history.push('content-templates/' + evt?.data?.contentTemplateID);
      }
    }
    window.addEventListener('message', loadHandler);

    return () => {
      window.removeEventListener('message', loadHandler);
    };
  });

  return (
    <div role="main" className="content-templates-page">
      <iframe src={appURL} ref={frameRef} className="app-frame"></iframe>
    </div>
  );
}
