import React from 'react';
import { createRoot } from 'react-dom/client';
import { ReduxRoot } from './ReduxRoot';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider } from 'auth/providers/AuthProvider';
import { FeatureFlagsProvider } from 'providers/flagsmith/FeatureFlagsProvider';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <AuthProvider>
    <FeatureFlagsProvider>
      <ReduxRoot />
    </FeatureFlagsProvider>
  </AuthProvider>,
);
