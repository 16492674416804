import React, { useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'auth/providers/AuthProvider';
import './CreateOrManageContentTemplate.scss';

const editAppURL = process.env.REACT_APP_EXPERIENCE_BUILDER + '/#/content/templates/edit/';
const createAppURL = process.env.REACT_APP_EXPERIENCE_BUILDER + '/#/content/templates/create';
const host = process.env.REACT_APP_HOST_URL;

export default function CreateOrManageContentTemplate(props: any) {
  const {
    match: {
      params: { contentTemplatesId },
    },
  } = props;
  const history = useHistory();
  const { token } = useContext(AuthContext);
  const frameRef = useRef(null);
  const resolvedAppURL =
    contentTemplatesId && contentTemplatesId !== 'create' ? editAppURL + contentTemplatesId : createAppURL;

  useEffect(() => {
    function loadHandler(evt: any){
      const frame = frameRef?.current ? (frameRef.current as HTMLIFrameElement) : null;
      if (
        evt.source !== frame?.contentWindow ||
        evt.origin !== process.env.REACT_APP_EXPERIENCE_BUILDER ||
        !evt?.data?.flb
      ) {
        return;
      }

      if (evt?.data?.type === 'flb.contenttemplateeditor.ready') {
        if (!frame || !token) {
          console.error('> Missing required frame inputs', frame, token?.slice(token?.length - 5));
          return;
        }
        frame.contentWindow?.postMessage(
          {
            flb: true,
            type: 'flb.session',
            token: token,
            host: host,
          },
          resolvedAppURL,
        );
      } else if (evt?.data?.type === 'flb.contenttemplate.manage') {
        history.goBack();
      }
    }
    window.addEventListener('message', loadHandler);

    return () => {
      window.removeEventListener('message', loadHandler);
    };
  });

  return (
    <div className="create-or-manage-content-template">
      <iframe src={resolvedAppURL} ref={frameRef} className="app-frame"></iframe>
    </div>
  );
}
